import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'

import 'highlight.js/styles/github-dark.css'
import './styles.css'

interface BlogProps {
  data?: {
    nodeBlog?: {
      title: string
      body: {
        value: string
      }
      created: string
      relationships: {
        field_author: {
          title: string
        }
        field_topic: {
          name: string
        }
      }
    }
  }
}

const Blog: React.FC<BlogProps> = ({ data }: BlogProps) => {
  const node = data?.nodeBlog

  return (
    <Layout title={node.title}>
      <div className="relative overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
            <svg className="absolute top-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
              <defs>
                <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
            <svg className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
              <defs>
                <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg className="absolute bottom-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
              <defs>
                <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg prose mx-auto">
            <div className="block text-base text-blue-400 font-semibold tracking-wide uppercase">{node?.relationships?.field_topic?.name}</div>
            <h1 style={{ marginBottom: 0 }}>
              <span className="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl">{node.title}</span>
            </h1>
            <div className="mt-2 text-gray-300">
              {`By ${node?.relationships?.field_author?.title} `}
              &middot;
              {` ${node?.created}`}
            </div>
          </div>
          <div className="mt-6 prose custom-html prose-indigo prose-lg mx-auto text-white">
            <div dangerouslySetInnerHTML={{ __html: node?.body?.value }} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const BlogQuery = graphql`
  query BlogQuery($drupal_internal__nid: Int!) {
    nodeBlog(drupal_internal__nid: {eq: $drupal_internal__nid}) {
      title
      body {
        value
      }
      created(formatString: "Do [of] MMMM, YYYY")
      relationships {
        field_author {
          title
        }
        field_topic {
          name
        }
      }
    }
  }
`

export default Blog
